import { makeStyles } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import TailwindButton from "@RHCommerceDev/component-tailwind-button";
import { splitFont } from "aem/ui/authoring/rh/FontPicker2";
import { getAnalyticsAttrsFromProps } from "analytics";
import classNames from "classnames";
import { useEnv } from "hooks/useEnv";
import RHArrowIcon from "icon-arrow";
import React from "react";
import { BREAKPOINT_MD, BREAKPOINT_SM } from "utils/constants";
import {
  ButtonProps,
  Button as MUIButton,
  Theme,
  useMediaQuery
} from "utils/material-ui-core";
import yn from "yn";

const WHITE_COLOR = "white";
const BLACK_COLOR = "black";
const TRANSPARENT = "transparent";

export interface ButtonExtProps extends ButtonProps {
  endIcon?: boolean;
  endIconSm?: boolean;
  endIconMd?: boolean;
  endIconStroke?: boolean;
  endIconStrokeSm?: boolean;
  endIconStrokeMd?: boolean;
  text?: string;
  textSm?: string;
  textMd?: string;
  hide?: boolean;
  hideSm?: boolean;
  hideMd?: boolean;
  onclick?: Function;
  startIcon?: boolean;
  startIconSm?: boolean;
  startIconMd?: boolean;
  variantSm?: ButtonProps["variant"];
  variantMd?: ButtonProps["variant"];
  colorSm?: ButtonProps["color"];
  colorMd?: ButtonProps["color"];
  fullWidthSm?: boolean;
  hideClassNames?: string;
  width?: string;
  widthSm?: string;
  widthMd?: string;
  height?: string;
  heightSm?: string;
  heightMd?: string;
  padding?: string;
  paddingSm?: string;
  paddingMd?: string;
  align?: string;
  alignMd?: string;
  alignSm?: string;
  textWrap?: boolean;
  textWrapSm?: boolean;
  textWrapMd?: boolean;
  startIconSpacing?: number;
  endIconSpacing?: number;
  font?: string;
  fontMd?: string;
  fontSm?: string;
  fontSize?: number;
  fontSizeMd?: number;
  fontSizeSm?: number;
}

// TODO: SOME of these can be moved to src/theme/index.tsx - but need to validate first
const useStyles = props =>
  makeStyles((theme: Theme) => ({
    root: {
      transition: "all 1s ease",
      whiteSpace: "nowrap",
      minWidth: "auto"
    },
    label: {
      display: "flex"
    },
    startIcon: {
      transform: "rotate(180deg)"
    },
    containedPrimary: {
      "&:hover": {
        backgroundColor: WHITE_COLOR,
        color: BLACK_COLOR
      },
      "&:focus": {
        outline: "1px solid #000000",
        outlineOffset: "2px"
      }
    },
    containedSecondary: {
      "&:hover": {
        backgroundColor: BLACK_COLOR,
        color: WHITE_COLOR
      },
      "&:focus": {
        outline: "1px solid #000000",
        outlineOffset: "2px"
      }
    },
    outlinedPrimary: {
      borderColor: BLACK_COLOR,
      color: BLACK_COLOR,
      backgroundColor: TRANSPARENT,
      "&:hover": {
        backgroundColor: BLACK_COLOR,
        color: WHITE_COLOR,
        borderColor: BLACK_COLOR
      }
    },
    outlinedSecondary: {
      borderColor: WHITE_COLOR,
      color: WHITE_COLOR,
      backgroundColor: TRANSPARENT,
      "&:hover": {
        borderColor: WHITE_COLOR,
        color: BLACK_COLOR,
        backgroundColor: WHITE_COLOR
      }
    },
    textPrimary: {
      color: "#000",
      fontWeight: 400,
      backgroundColor: TRANSPARENT,
      "&:hover": {
        color: "#CCC",
        backgroundColor: TRANSPARENT
      }
    },
    textSecondary: {
      color: "#FFF",
      fontWeight: 400,
      backgroundColor: TRANSPARENT,
      "&:hover": {
        color: "#CCC",
        backgroundColor: TRANSPARENT
      }
    },
    textButtonLeft: {
      textAlign: "left",
      whiteSpace: "normal",
      display: "inline"
    },
    textButtonRight: {
      textAlign: "right",
      whiteSpace: "normal",
      display: "inline"
    },
    textButtonCenter: {
      textAlign: "center",
      whiteSpace: "normal",
      display: "inline"
    },
    sizeLarge: {
      fontSize: theme.typography.pxToRem(14)
    },
    caret: {
      marginLeft: "15px"
    },
    endIcon: {
      fontSize: `86% !important`,
      height: props.isFeatureFlagIcon ? `16px !important` : `9px !important`,
      width: props.isFeatureFlagIcon ? `16px !important` : `13px !important`,
      marginLeft: props.endIconSpacing ?? 4
    },
    textWrap: {
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        whiteSpace: props.textWrap ? "normal" : "nowrap"
      },

      [theme.breakpoints.between(BREAKPOINT_SM, BREAKPOINT_MD)]: {
        whiteSpace: props.textWrapMd ? "normal" : "nowrap"
      },

      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        whiteSpace: props.textWrapSm ? "normal" : "nowrap"
      }
    },
    underline: {
      "& .MuiButton-label": {
        fontFamily: `RHSans !important`,
        fontStyle: `normal !important`,
        lineHeight: "120%",
        letterSpacing: "0.04em",
        textTransform: "uppercase",
        display: "inline-block",
        "& span": {
          borderBottom: "1px solid"
        }
      }
    },
    underlineSecondary: {
      color: "#FFF",
      backgroundColor: TRANSPARENT,
      "&:hover": {
        color: "#CCC",
        backgroundColor: TRANSPARENT
      }
    },
    underlinePrimary: {
      color: "#000",
      backgroundColor: TRANSPARENT,
      "&:hover": {
        color: "#CCC",
        backgroundColor: TRANSPARENT
      }
    }
  }));

export type ButtonPropTypes = ButtonExtProps;

export const Button: React.FC<ButtonExtProps> = props => {
  const env = useEnv();
  const FEATURE_TAILWIND_ICON = yn(env.FEATURE_TAILWIND_ICON);
  const classes = useStyles({
    ...props,
    isFeatureFlagIcon: FEATURE_TAILWIND_ICON
  })();
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
  const mdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));

  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const ButtonComponent = FEATURE_TAILWIND_COMPONENTS
    ? TailwindButton
    : MUIButton;

  classes.root = classes.root + " " + props.hideClassNames;

  const {
    endIcon = null,
    endIconSm = null,
    endIconMd = null,
    endIconStroke = null,
    endIconStrokeSm = null,
    endIconStrokeMd = null,
    text = null,
    textSm = null,
    textMd = null,
    startIcon = null,
    startIconSm = null,
    startIconMd = null,
    color = "primary",
    colorSm = "primary",
    colorMd = "primary",
    hide,
    hideMd,
    hideSm,
    variant = "text",
    variantSm = "text",
    variantMd = "text",
    width,
    widthSm,
    widthMd,
    height,
    heightSm,
    heightMd,
    padding,
    paddingMd,
    paddingSm,
    align = "center",
    alignMd = "center",
    alignSm = "center",
    onclick,
    font,
    fontMd,
    fontSm,
    fontSize,
    fontSizeMd,
    fontSizeSm
  } = props;

  const [fontFamily, fontStyle, fontWeight] = splitFont(font ?? "");
  const [fontFamilyMd, fontStyleMd, fontWeightMd] = splitFont(fontMd ?? "");
  const [fontFamilySm, fontStyleSm, fontWeightSm] = splitFont(fontSm ?? "");

  const analyticsAttrs = getAnalyticsAttrsFromProps(props);
  const fn = onclick ? () => onclick() : undefined;

  let rootClassMd = "";
  let rootClassSm = "";
  let rootClass = "";

  if (variantMd === "text" && alignMd === "left") {
    rootClassMd = classNames(
      classes.root,
      classes.textButtonLeft,
      classes.textWrap
    );
  } else if (variantMd === "text" && alignMd === "right") {
    rootClassMd = classNames(
      classes.root,
      classes.textButtonRight,
      classes.textWrap
    );
  } else if (variantMd === "text" && alignMd === "center") {
    rootClassMd = classNames(
      classes.root,
      classes.textButtonCenter,
      classes.textWrap
    );
  } else {
    rootClassMd = classes.root;
  }

  if (variantSm === "text" && alignSm === "left") {
    rootClassSm = classNames(
      classes.root,
      classes.textButtonLeft,
      classes.textWrap
    );
  } else if (variantSm === "text" && alignSm === "right") {
    rootClassSm = classNames(
      classes.root,
      classes.textButtonRight,
      classes.textWrap
    );
  } else if (variantSm === "text" && alignSm === "center") {
    rootClassSm = classNames(
      classes.root,
      classes.textButtonCenter,
      classes.textWrap
    );
  } else {
    rootClassSm = classes.root;
  }

  if (variant === "text" && align === "left") {
    rootClass = classNames(
      classes.root,
      classes.textButtonLeft,
      classes.textWrap
    );
  } else if (variant === "text" && align === "right") {
    rootClass = classNames(
      classes.root,
      classes.textButtonRight,
      classes.textWrap
    );
  } else if (variant === "text" && align === "center") {
    rootClass = classNames(
      classes.root,
      classes.textButtonCenter,
      classes.textWrap
    );
  } else {
    rootClass = classes.root;
  }

  return smDown ? (
    hideSm ? null : (
      <ButtonComponent
        {...analyticsAttrs}
        classes={{ ...classes, root: rootClassSm }}
        startIcon={startIconSm ? <ArrowRightAltIcon /> : null}
        endIcon={
          endIconSm ? (
            <RHArrowIcon
              className={classes.endIcon}
              strokeColor={endIconStrokeSm ? "currentColor" : undefined}
              style={{ overflow: endIconStrokeSm ? "visible" : undefined }}
            />
          ) : null
        }
        color={colorSm}
        variant={variantSm}
        onClick={fn}
        ref={el => {
          if (el) {
            el.style.setProperty("padding", paddingSm as any, "important");
          }
        }}
        style={{
          fontFamily: fontFamilySm,
          fontStyle: fontStyleSm,
          fontSize: fontSizeSm,
          fontWeight: fontWeightSm as any,
          width: widthSm,
          height: heightSm
        }}
      >
        <span>{textSm ?? text}</span>
      </ButtonComponent>
    )
  ) : mdDown ? (
    hideMd ? null : (
      <ButtonComponent
        {...analyticsAttrs}
        classes={{ ...classes, root: rootClassMd }}
        startIcon={startIconMd ? <ArrowRightAltIcon /> : null}
        endIcon={
          endIconMd ? (
            <RHArrowIcon
              className={classes.endIcon}
              strokeColor={endIconStrokeMd ? "currentColor" : undefined}
              style={{ overflow: endIconStrokeMd ? "visible" : undefined }}
            />
          ) : null
        }
        color={colorMd}
        variant={variantMd}
        onClick={fn}
        ref={el => {
          if (el) {
            el.style.setProperty("padding", paddingMd as any, "important");
          }
        }}
        style={{
          fontFamily: fontFamilyMd,
          fontStyle: fontStyleMd,
          fontSize: fontSizeMd,
          fontWeight: fontWeightMd as any,
          width: widthMd,
          height: heightMd
        }}
      >
        <span>{textMd ?? text}</span>
      </ButtonComponent>
    )
  ) : hide ? null : (
    <ButtonComponent
      {...analyticsAttrs}
      classes={{ ...classes, root: rootClass }}
      startIcon={startIcon ? <ArrowRightAltIcon /> : null}
      endIcon={
        endIcon ? (
          <RHArrowIcon
            className={classes.endIcon}
            strokeColor={endIconStroke ? "currentColor" : undefined}
            style={{ overflow: endIconStroke ? "visible" : undefined }}
          />
        ) : null
      }
      color={color}
      variant={variant}
      onClick={fn}
      ref={el => {
        if (el) {
          el.style.setProperty("padding", padding as any, "important");
        }
      }}
      style={{
        fontFamily: fontFamily,
        fontStyle: fontStyle,
        fontSize: fontSize,
        fontWeight: fontWeight as any,
        width: width,
        height: height
      }}
    >
      <span>{text}</span>
    </ButtonComponent>
  );
};

Button.defaultProps = {
  textWrap: true,
  textWrapSm: true,
  textWrapMd: true
};

export default Button;
